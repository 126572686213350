export default {
    data({ data }) {
        return data;
    },
    errors({ validationErrors }) {
        return validationErrors;
    },
    isDeleting({ deleting }) {
        return deleting;
    },
    isLoading({ loading }) {
        return loading;
    },
    isSaving({ saving }) {
        return saving;
    },
    pagination({ page }) {
        return page;
    },
    single({ single }) {
        return single;
    }
};
