import {
    OVERVIEW_BATCH_DELETE_REQUEST,
    OVERVIEW_BATCH_DELETE_SUCCESS,
    OVERVIEW_BATCH_DELETE_FAILURE,
    OVERVIEW_FETCH_MANY_REQUEST,
    OVERVIEW_FETCH_MANY_SUCCESS,
    OVERVIEW_FETCH_MANY_FAILURE,
    OVERVIEW_FETCH_REQUEST,
    OVERVIEW_FETCH_SUCCESS,
    OVERVIEW_FETCH_FAILURE,
    OVERVIEW_SAVE_REQUEST,
    OVERVIEW_SAVE_SUCCESS,
    OVERVIEW_SAVE_FAILURE,
    EMPTY_OVERVIEW,
    CLEAR_ERRORS
} from "./types";

export default {
    deleteMany({ commit }, { ids = [] }) {
        commit(OVERVIEW_BATCH_DELETE_REQUEST);
        return window.axios
            .post(`/overview/statistics`, { _method: "DELETE", ids })
            .then(response => {
                commit(OVERVIEW_BATCH_DELETE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(OVERVIEW_BATCH_DELETE_FAILURE, error);
                throw error;
            });
    },
    fetchMany({ commit }, { query = "", page = 1, extraParams = {} }) {
        commit(OVERVIEW_FETCH_MANY_REQUEST);
        return window.axios
            .get(`/overview/statistics`)
            .then(response => {
                commit(OVERVIEW_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(OVERVIEW_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    fetch({ commit }, { start = null, end = null, page }) {
        commit(OVERVIEW_FETCH_REQUEST);
        return window.axios
        .get(`/overview/statistics`, { params: { start_date: start, end_date: end, page } })
        .then(response => {
            commit(OVERVIEW_FETCH_SUCCESS, response);
            return response;
        })
        .catch(error => {
            commit(OVERVIEW_FETCH_FAILURE, error);
            throw error;
        });
    },
    save({ commit }, user) {
        const path = user.id ? `/users/${user.id}` : "/users";
        const method = user.id ? "put" : "post";

        commit(OVERVIEW_SAVE_REQUEST);
        return window.axios[method](path, user)
            .then(response => {
                commit(OVERVIEW_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(OVERVIEW_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(OVERVIEW_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    empty({ commit }) {
        commit(EMPTY_OVERVIEW);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    }
};
