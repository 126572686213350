export const OVERVIEW_BATCH_DELETE_REQUEST = "OVERVIEW_BATCH_DELETE_REQUEST";
export const OVERVIEW_BATCH_DELETE_SUCCESS = "OVERVIEW_BATCH_DELETE_SUCCESS";
export const OVERVIEW_BATCH_DELETE_FAILURE = "OVERVIEW_BATCH_DELETE_FAILURE";

export const OVERVIEW_FETCH_MANY_REQUEST = "OVERVIEW_FETCH_MANY_REQUEST";
export const OVERVIEW_FETCH_MANY_SUCCESS = "OVERVIEW_FETCH_MANY_SUCCESS";
export const OVERVIEW_FETCH_MANY_FAILURE = "OVERVIEW_FETCH_MANY_FAILURE";

export const OVERVIEW_FETCH_REQUEST = "OVERVIEW_FETCH_REQUEST";
export const OVERVIEW_FETCH_SUCCESS = "OVERVIEW_FETCH_SUCCESS";
export const OVERVIEW_FETCH_FAILURE = "OVERVIEW_FETCH_FAILURE";

export const OVERVIEW_SAVE_REQUEST = "OVERVIEW_SAVE_REQUEST";
export const OVERVIEW_SAVE_SUCCESS = "OVERVIEW_SAVE_SUCCESS";
export const OVERVIEW_SAVE_FAILURE = "OVERVIEW_SAVE_FAILURE";

export const EMPTY_OVERVIEW = "EMPTY_OVERVIEW";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
