<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col class="mb-4 mb-lg-0" cols="12">
        <h1 class="font-weight-bold mb-0 text-primary">Overview</h1>
      </b-col>
    </b-row>

    <!-- Weekly Statistics Section -->
    <div>
      <b-form @submit.prevent="submit">
        <b-card class="mb-4">
          <b-row class="mb-4">
            <b-col cols="12">
              <p class="mb-0 h5 font-weight-bold d-flex">Weekly Statistics</p>
              <small class="text-muted">Select dates to view week specific details.</small>
            </b-col>
            <hr/>
            <b-col class="" cols="6">
              <b-form-group class="mt-2" label="Date From">
                <b-form-datepicker v-model="dateFrom" :date-disabled-fn="dateDisabled" placeholder="Click to select..."
                                   reset-button @input="onDateChanged"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col class="" cols="6">
              <b-form-group class="mt-2" label="Date To">
                <b-form-datepicker v-model="dateTo" :date-disabled-fn="dateDisabled" placeholder="Click to select..."
                                   reset-button
                                   @input="onDateChanged"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4 mt-4">
            <b-col class="order-lg-2 order-xl-1" cols="12" lg="6" xl="4">
              <b-card class="shadow-sm rounded">
                <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                  <h5 class="text-muted">Stock Sold</h5>
                  <span v-if="!isLoading" class="display-4">{{ single.stockOutgoing }}</span>
                  <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
                </b-card-body>
              </b-card>
            </b-col>

            <b-col class="order-lg-1 order-xl-2 mb-lg-4" cols="12" xl="4">
              <b-card class="shadow-sm rounded">
                <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                  <h5 class="text-muted">Turnover</h5>
                  <span v-if="!isLoading" class="display-4">{{ single.weeklyEarnings | currencyFormat }}</span>
                  <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
                </b-card-body>
              </b-card>
            </b-col>

            <b-col class="order-lg-3 order-xl-3" cols="12" lg="6" xl="4">
              <b-card class="shadow-sm rounded">
                <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                  <h5 class="text-muted text-center">Stock Bought</h5>
                  <span v-if="!isLoading" class="display-4">{{ single.stockIncoming }}</span>
                  <b-spinner v-if="isLoading" class="mt-1" variant="secondary"></b-spinner>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </div>
    <hr/>

    <!-- Overview Table -->
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-table-simple class="overview-table" hover responsive striped>
            <b-thead>
              <tr>
                <th class="text-center" colspan="4">Order Details</th>
                <th class="text-center" colspan="4">Fitting</th>
              </tr>
              <tr>
                <!-- Order Value -->
                <th class="text-center">Name</th>
                <th class="text-center">Order</th>
                <th class="text-center">Postcode</th>
                <th class="text-center">Supplier</th>

                <!-- Fitting -->
                <th class="text-center">Fitting Value</th>
                <th class="text-center">Fitting Date</th>
                <th class="text-center">Item Status</th>
              </tr>
            </b-thead>
            <b-tbody v-if="!isLoading">
              <tr v-for="(stockLevelItem, ind) in single.stockList.data" :key="`stocklevelitem${ind}`">
                <!-- Order Details -->
                <td class="text-center">{{ stockLevelItem.order.customer.name }}</td>
                <td class="text-center">
                  <router-link :to="{ name: 'dash.orders.view', params: { id: stockLevelItem.order_id } }"
                               class="order-button">
                    {{ stockLevelItem.order_id }}
                  </router-link>
                </td>
                <td class="text-center">{{ stockLevelItem.order.customer.fitting_postcode }}</td>
                <td class="text-center">
                  {{ stockLevelItem.supplier_id ? stockLevelItem.supplier.name : "Not Specified." }}
                </td>

                <!-- Fitting Details -->
                <td v-if="!stockLevelItem.fitting_cost" class="text-center">No Fitting Cost</td>
                <td v-else class="text-center">{{ stockLevelItem.fitting_cost | currencyFormat }}</td>
                <td v-if="stockLevelItem.fitting_date == null" class="text-center">No Fitting Date</td>
                <td v-else class="text-center">{{ moment(stockLevelItem.fitting_date).format("Do MMM YYYY") }}</td>
                <td v-if="stockLevelItem.supply_order_item_lot" class="text-center status-link">
                  <router-link v-b-popover.bottom="'View this supply order'"
                               :class="rowClassStatus(stockLevelItem.supply_order_item_lot.supply_order_item)"
                               :to="{
                                 name: 'dash.supply_orders.view',
                                 params: { id: stockLevelItem.supply_order_item_lot.supply_order_item.supply_order_id }
                               }">
                    <span class="text-dark">{{ stockLevelItem.supply_order_item_lot.supply_order_item.status }}</span>
                  </router-link>
                </td>
                <td v-if="!stockLevelItem.supply_order_items[0] && !stockLevelItem.supply_order_item_lot"
                    class="status-link">
                  <span class="goods-in"><span class="text-dark">Goods In (Stock)</span></span>
                </td>
                <td v-if="stockLevelItem.supply_order_items[0] && !stockLevelItem.supply_order_item_lot"
                    class="status-link">
                  <router-link v-for="supplyOrderItem in stockLevelItem.supply_order_items"
                               :key="`supply-order-item-${supplyOrderItem.id}`"
                               v-b-popover.bottom="'View this supply order'"
                               :class="rowClassStatus(supplyOrderItem)"
                               :to="{ name: 'dash.supply_orders.view', params: { id: supplyOrderItem.supply_order_id } }">
                    <span class="text-dark">{{ supplyOrderItem.status }}</span>
                  </router-link>
                </td>
              </tr>
            </b-tbody>
          </b-table-simple>
          <b-pagination v-model="pagination.current" :per-page="pagination.per" :total-rows="pagination.total"
                        @change="onPaginate"></b-pagination>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import momentMixin from "../../mixins/momentMixin"

export default {
  mixins: [currentUser, momentMixin],

  created() {
    this.dateFrom = this.moment()
      .startOf("year")
      .format("YYYY-MM-DD")
    this.dateTo = this.moment()
      .endOf("year")
      .format("YYYY-MM-DD")

    this.fetch({
      start: this.dateFrom,
      end: this.dateTo,
      page: this.pagination.current
    }).then(response => {
      this.pagination = {
        current: response.data.stockList.current_page,
        per: response.data.stockList.per_page,
        total: response.data.stockList.total
      }
    })
  },

  computed: {
    ...mapGetters("overview", ["isLoading", "single"]),

    weekSelector() {
      return this.moment(this.dates)
        .add(8, "days")
        .format("DD/MM/YYYY")
    }
  },

  data() {
    return {
      collapsed: {
        stock_levels: !this.$route.params.id,
      },

      stockOutgoing: null,
      stockIncoming: null,
      stockList: null,
      weeklyEarnings: null,
      supply_order_item: null,
      supply_order_id: null,

      dateFrom: null,
      dateTo: null,

      pagination: {
        current: 1,
        per: null,
        total: null
      }
    }
  },

  methods: {
    ...mapActions("overview", ["fetch"]),

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    onDateChanged() {
      window.requestAnimationFrame(() => {
        this.fetch({
          start: this.dateFrom,
          end: this.dateTo,
          page: this.pagination.current
        }).then(response => {
          this.pagination = {
            current: response.data.stockList.current_page,
            per: response.data.stockList.per_page,
            total: response.data.stockList.total
          }
        })
      })
    },

    onPaginate() {
      window.requestAnimationFrame(() => {
        this.fetch({
          start: this.dateFrom,
          end: this.dateTo,
          page: this.pagination.current
        }).then(response => {
          this.pagination = {
            current: response.data.stockList.current_page,
            per: response.data.stockList.per_page,
            total: response.data.stockList.total
          }
        })
      })
    },

    rowClass(stockLevelItem) {
      if (stockLevelItem.quantity <= 0) {
        this.class = "danger"
        return this.class
      }
      if (stockLevelItem.quantity >= 50 && stockLevelItem.quantity <= 99) {
        this.class = "warning"
        return this.class
      } else {
        this.class = "success"
        return this.class
      }
    },

    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`)
      const weekday = date.getDay()
      // Return `true` if the date should be disabled
      return weekday === 0
    },

    rowClassStatus(supplyOrderItem) {
      if (supplyOrderItem.status === "Ordered") {
        this.class = "ordered"
        return this.class
      }
      if (supplyOrderItem.status === "Goods In") {
        this.class = "goods-in"
        return this.class
      }
      if (supplyOrderItem.status === "Partial Goods In") {
        this.class = "part-goods-in"
        return this.class
      }
      if (supplyOrderItem.status === "Faulted") {
        this.class = "faulted"
        return this.class
      }
      if (supplyOrderItem.status === "Not Ordered") {
        this.class = "not-ordered"
        return this.class
      }
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style lang="scss">
.status-link {
  a {
    width: 100%;
    height: auto;
    display: flex;
    text-align: center;
    margin: auto;

    span {
      width: 100%;
    }
  }
}

.order-button {
  background: purple;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders {
  background: #f0cb97;
}

.fitting {
  background: #95ec95;
}

.profit {
  background: rgb(161, 142, 243);
}

.danger {
  color: white;
  background: #d9534f;
}

.warning {
  color: white;
  background: #f0ad4e;
}

.success {
  color: white;
  background: #5cb85c;
}

.ordered {
  color: white;
  background: #ffbf00;
}

.goods-in {
  color: white;
  background: #00ff00;
}

.part-goods-in {
  color: white;
  background: #3792cb;
}

.faulted {
  color: white;
  background: #ff0000;
}

.not-ordered {
  color: black;
  background: white;
}

.overview-table table {
  th,
  td {
    border-top: none;
  }

  td {
    padding: 0.75rem 0.5rem;
  }
}
</style>
